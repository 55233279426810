import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import DevelopmentTimeChart from "./graph";
import Login from "./login";
import { Badge } from "@mui/material";

const Mbutton = ({ text, href, onClick , children=[] , required = true  , neu=false}) => {
  const [detail, setDetail] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
 const login = cookies["login"];
  useEffect(() => {
    //console.log("cookies", cookies);
  }, [detail]);

  return (
    <div className=" w-full justify-evenly z-100    "

    
        onMouseOver={() => setDetail(true)}
        onMouseOut={() => setDetail(false)}
      >
         
        <a

        className={`  duration-300 flex  h-24 items-center justify-center    menuitem    `}
     
        onClick={onClick}
        href={href}
      >
        {text}
        {
          neu ? 
        
        <Badge badgeContent={'new'} variant="soft" color="error" className="h-1/2" > </Badge> :""} 
      </a>
      
     
      
      {(required && !login) ? <></> :
        <div className= {` duration-700      items-center w-full left-0 menu-drop   absolute ${detail ?  'h-96  overflow-hidden ' : 'h-0  overflow-hidden '} `}  >
            <div className="absolute w-full flex items-center justify-evenly m-auto p-8 h-full ">

                   {
                     children }
            </div>
        
          
        </div>}
      
      
    </div>
  );
};

export default Mbutton;

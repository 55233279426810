import Home from './pages/home';
import PrivacyTerms from './pages/privacy';

import './App.css';
import { useCookies } from 'react-cookie';

import Youtube from './pages/youtube';
import NotFound from './pages/notfound';
import Header from './components/header';
import Container from './pages/container';  

import {
  createBrowserRouter,
  RouterProvider,

} from "react-router-dom";
import Downloads from './pages/downloads';
import Ai from './pages/ai';
import Footer from './components/footer';
import Register from './pages/register';
import Blog from './pages/blog';
import useFacebookSDK from './components/facebook';
import Login from './components/login';
import Instructions from './pages/instructions';
import CookieConsent from './components/cookieconsent';
import VisitorTracker from './components/tracker';
import VisitorTrackerWithCookiesConsent from './components/cookieConsentTracker';
import ChatbotSetupInstructions from './pages/chatbot_instructions';



function App() {

  
  const [cookies, setCookie, removeCookie] = useCookies();
  
  
  const login = cookies["login"];



  const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "ai",
    element: <Ai />,
  },
  {
    path: "youtube",
    element: login ? <Youtube/> : <Home/>,
  },
    {
    path: "*",
    element: login ? <NotFound/> : <Login/>,
  },
  {
    path: "register",
    element:  <Register/> ,
  },

  {
    path: "blog",
    element:  <Blog/> ,
  },{
      path: "container",
    element:   login ? <Container/> : <Login/>  ,
  },
  {
    path: "privacy",
    element: <PrivacyTerms />
  },
    {
    path: "instruction",
    element: <Instructions />
  },
  {
    path: "chatbotinstructions",
    element: <ChatbotSetupInstructions />
  },
  {
    path: "downloads",  
    element: <Downloads />
  },
]);


useFacebookSDK();
  return (
    <div className="  flex flex-col  h-screen  ">
      

{
         <Header/> 
         
      }





 <RouterProvider router={router} />


<VisitorTrackerWithCookiesConsent/>

     <Footer/>  
     
    </div>
  
);

}

export default App;
